@tailwind base;

@font-face {
  font-family: 'Nexa-Thin';
  src: url('../public/fonts//Nexa-Thin/font.woff2') format('woff2'), url('../public/fonts//Nexa-Thin/font.woff') format('woff');
}
@font-face {
  font-family: 'Nexa-ThinItalic';
  src: url('../public/fonts//Nexa-ThinItalic/font.woff2') format('woff2'), url('../public/fonts//Nexa-ThinItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'Nexa-Light';
  src: url('../public/fonts//Nexa-Light/font.woff2') format('woff2'), url('../public/fonts//Nexa-Light/font.woff') format('woff');
}
@font-face {
  font-family: 'Nexa-LightItalic';
  src: url('../public/fonts//Nexa-LightItalic/font.woff2') format('woff2'), url('../public/fonts//Nexa-LightItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('../public/fonts//Nexa/normal_normal_normal.woff2') format('woff2'), url('../public/fonts//Nexa/normal_normal_normal.woff') format('woff');
}

@font-face {
  font-family: 'Nexa';
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url('../public/fonts//Nexa/italic_normal_normal.woff2') format('woff2'), url('../public/fonts//Nexa/italic_normal_normal.woff') format('woff');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url('../public/fonts//Nexa/normal_normal_bold.woff2') format('woff2'), url('../public/fonts//Nexa/normal_normal_bold.woff') format('woff');
}
@font-face {
  font-family: 'Nexa';
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  src: url('../public/fonts//Nexa/italic_normal_bold.woff2') format('woff2'), url('../public/fonts//Nexa/italic_normal_bold.woff') format('woff');
}

html,
body {
  font-family: Nexa, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 16px;
  text-rendering: optimizelegibility;
  text-size-adjust:100%;
  -webkit-font-smoothing: antialiased;  
}

.recharts-cartesian-axis-tick-value{
  font-size: 10px;
}

@tailwind components;

@tailwind utilities;

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

